
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { FormatRuleContent as Content } from "@/models/rules/contents/FormatRuleContent";

@Component
export default class FormatRuleContent extends Vue {
    @Prop({ type: Object, required: true })
    public readonly content!: Content;

    protected formats = [
        "_",
        "caseid",
        "codelvl1",
        "codelvl2",
        "codevlv3",
        "codevlv4",
        "firstname",
        "lastname",
        "countrycode",
        "current date",
        "created date",
        "email",
        "vin",
        "[XY]",
        "_[",
        "]_",
        "space",
        "BOARD CASE",
        "escalationlvl",
        "GWKS"
    ];

    created(): void {
        if (!this.content.format) this.content.format = [];
    }

    newFormat(value: string): void {
        this.content.format.push(value);
        this.$forceUpdate();
    }

    removeFormat(index: number): void {
        this.content.format.splice(index, 1);
        this.$forceUpdate();
    }
}
