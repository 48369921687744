
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import { Rule } from "@/models/rules/Rule";
import { Routes } from "@/router/routes";
import { ruleModule } from "@/store/modules/rule";
import { RuleActions } from "@/store/modules/rule/actions";
import { RuleGetters } from "@/store/modules/rule/getters";
import Vue from "vue";
import Component from "vue-class-component";
import DynamicTitle from "@/components/DynamicTitle";
import RuleEditor from "@/components/RuleEditor";

@Component({
    components: {
        DynamicTitle,
        RuleEditor
    },
    methods: {
        ...ruleModule.mapActions({
            createRule: RuleActions.CreateRule
        })
    },
    computed: {
        ...ruleModule.mapGetters({
            errors: RuleGetters.Errors,
            loading: RuleGetters.IsLoading
        })
    }
})
export default class RuleCreate extends Vue {
    private readonly createRule!: (rule: Partial<Rule>) => Promise<void>;

    protected readonly errors!: string[] | null;
    protected readonly loading!: boolean;

    protected snackbar = false;

    protected readonly titleElements: TitleElement[] = [
        { name: "Rules", selected: false, link: Routes.Rules },
        { name: "Create", selected: true }
    ];

    get snackbarErrors(): string {
        if (this.errors?.length) {
            return this.errors.join(" ");
        }
        return "Unknown error!";
    }

    $refs!: {
        form: Vue & { validate: () => boolean };
    };

    protected currentRuleModel: Partial<Rule> = {
        caseSourceId: null,
        caseInputChannelId: null,
        codeLevelTypeId: null,
        codeLevelBrandId: null,
        codeLevelAreaId: null,
        codeLevelTopicGroupId: null,
        codeLevelTopicDetailId: null
    };

    async submit(): Promise<void> {
        const result = this.$refs.form.validate();
        if (result) {
            await this.createRule(this.currentRuleModel);

            if (!this.errors?.length) {
                this.$router.push(Routes.Rules);
            } else {
                this.snackbar = true;
            }
        }
    }
}
