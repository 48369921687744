
import Vue from "vue";
import DynamicTitle from "@/components/DynamicTitle";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import { ruleModule } from "@/store/modules/rule";
import { RuleActions } from "@/store/modules/rule/actions";
import { RuleGetters } from "@/store/modules/rule/getters";
import { PaginationInfo } from "@/models/PaginationInfo";
import { PaginationResult } from "@/models/PaginationResult";
import { Rule } from "@/models/rules/Rule";
import { Component, Watch } from "vue-property-decorator";
import { Routes } from "@/router/routes";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { MarketActions } from "@/store/modules/market/actions";
import { Market } from "@/models/markets/Market";
import { RuleInfo } from "@/models/rules/RuleInfo";
import { LookupEntity } from "@/models/LookupEntity";
import { getSelectDataSource } from "@/services/dataSourceUtils";

@Component({
    components: {
        DynamicTitle,
        ConfirmationDialog
    },
    methods: {
        ...ruleModule.mapActions({
            loadRules: RuleActions.LoadRules,
            loadInfo: RuleActions.LoadInfo,
            deleteRule: RuleActions.DeleteRule
        }),
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets
        })
    },
    computed: {
        ...ruleModule.mapGetters({
            rules: RuleGetters.Rules,
            info: RuleGetters.Info,
            isLoading: RuleGetters.IsLoading
        }),
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets
        })
    }
})
export default class RuleList extends Vue {
    private readonly loadRules!: (payload: {
        info: PaginationInfo;
        ruleTypeId?: number | null;
        marketId?: number | null;
    }) => Promise<void>;
    private readonly loadMarkets!: () => Promise<void>;
    private readonly loadInfo!: () => Promise<void>;
    private readonly deleteRule!: (ruleId: number) => Promise<void>;

    protected readonly rules!: PaginationResult<Rule>;
    protected readonly info!: RuleInfo;
    protected readonly markets!: Market[];
    protected readonly isLoading!: boolean;

    protected readonly titleElements: TitleElement[] = [
        { name: "Rules", selected: true }
    ];

    protected options: { page?: number; itemsPerPage?: number } = {};

    protected headers = [
        { text: "ID", value: "ruleId", sortable: false },
        { text: "Priority", value: "priority", sortable: false },
        { text: "Markets", value: "marketIds", sortable: false },
        { text: "Type", value: "ruleTypeId", sortable: false },
        { text: "Source", value: "caseSourceId", sortable: false },
        {
            text: "Code Level Brand",
            value: "codeLevelBrandId",
            sortable: false
        },
        { text: "Code Level Area", value: "codeLevelAreaId", sortable: false },
        {
            text: "Actions",
            value: "actions",
            sortable: false,
            filterable: false
        }
    ];

    protected deleteDialog = false;
    private selectedItemId: number | null = null;

    protected selectedMarketId: number | null = null;
    protected selectedRuleTypeId: number | null = null;

    protected marketDataSource: LookupEntity<unknown>[] = [];
    protected ruleTypeDataSource: LookupEntity<unknown>[] = [];

    created(): void {
        this.getDataFromApi();
        this.loadMarkets().then(() => {
            this.marketDataSource = getSelectDataSource(
                this.markets.map((m) => ({
                    id: m.marketId,
                    name: `${m.countryCode} / ${m.name} (${m.region})`
                }))
            );
        });
        this.loadInfo().then(() => {
            this.ruleTypeDataSource = getSelectDataSource(this.info.ruleTypes);
        });
    }

    createNewRule(): void {
        this.$router.push(Routes.RuleCreate);
    }

    @Watch("options")
    updatedOptions(): void {
        this.getDataFromApi();
    }

    @Watch("selectedMarketId")
    updatedSelectedMarket(): void {
        this.resetPage();
    }

    @Watch("selectedRuleTypeId")
    updatedSelectedRuleType(): void {
        this.resetPage();
    }

    resetPage(): void {
        this.options = { ...this.options, page: 1 };
    }

    getDataFromApi(): void {
        const { page, itemsPerPage } = this.options;

        if (!page || !itemsPerPage) {
            this.loadRules({
                info: {
                    take: 20,
                    skip: 0
                },
                ruleTypeId: this.selectedRuleTypeId,
                marketId: this.selectedMarketId
            });
            return;
        }

        this.loadRules({
            info: {
                skip: (page - 1) * itemsPerPage,
                take: itemsPerPage
            },
            ruleTypeId: this.selectedRuleTypeId,
            marketId: this.selectedMarketId
        });
    }

    editItem(rule: Rule): void {
        if (rule.ruleId) {
            this.$router.push(Routes.RuleEdit + rule.ruleId);
        }
    }

    deleteItem(rule: Rule): void {
        this.selectedItemId = rule.ruleId;
        this.deleteDialog = true;
    }

    confirmDeleteMarket(): void {
        if (this.selectedItemId) {
            this.deleteRule(this.selectedItemId).then(() => {
                this.getDataFromApi();
            });
        }
    }

    getMarketInfo(marketIds: number[]): string {
        let result = "";
        marketIds.forEach((id) => {
            const market = this.markets.find((m) => m.marketId === id);
            if (market) {
                result += market.name + ", ";
            }
        });
        return result.substring(0, result.length - 2);
    }
}
