
import { CodeLevelData } from "@/models/CodeLevelData";
import { LookupEntity } from "@/models/LookupEntity";
import { Market } from "@/models/markets/Market";
import { Rule } from "@/models/rules/Rule";
import { RuleInfo } from "@/models/rules/RuleInfo";
import { getSelectDataSource } from "@/services/dataSourceUtils";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { MarketGetters } from "@/store/modules/market/getters";
import { ruleModule } from "@/store/modules/rule";
import { RuleActions } from "@/store/modules/rule/actions";
import { RuleGetters } from "@/store/modules/rule/getters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ForwardRuleContent from "./Contents/ForwardRuleContent";
import AddToSubjectRuleContent from "./Contents/AddToSubjectRuleContent";
import EmailRuleContent from "./Contents/EmailRuleContent";
import ConditionRuleContent from "./Contents/ConditionRuleContent";
import FormatRuleContent from "./Contents/FormatRuleContent";
import ruleHelper from "@/services/ruleHelper";

type CodeLevelArea = CodeLevelData["codeLevelAreas"][0];
type CodeLevelTopicGroup = CodeLevelArea["topicGroups"][0];

@Component({
    components: {
        ForwardRuleContent,
        AddToSubjectRuleContent,
        EmailRuleContent,
        ConditionRuleContent,
        FormatRuleContent
    },
    methods: {
        ...ruleModule.mapActions({
            loadInfo: RuleActions.LoadInfo
        }),
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets
        })
    },
    computed: {
        ...ruleModule.mapGetters({
            info: RuleGetters.Info
        }),
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets
        })
    }
})
export default class RuleEditor extends Vue {
    private readonly loadInfo!: () => Promise<void>;
    private readonly loadMarkets!: () => Promise<void>;

    protected readonly info!: RuleInfo | null;
    protected readonly markets!: Market[];

    @Prop({ type: Object, required: true })
    public readonly rule!: Partial<Rule>;

    @Prop({ type: Boolean, default: false })
    public readonly loading!: boolean;

    get ruleTypes(): LookupEntity<unknown>[] {
        if (!this.info) return [];

        return getSelectDataSource(this.info.ruleTypes, false);
    }

    get sources(): LookupEntity<unknown>[] {
        if (!this.info) return [];

        return getSelectDataSource(this.info.caseSources, true, "*");
    }

    get inputChannels(): LookupEntity<unknown>[] {
        if (!this.info) return [];

        return getSelectDataSource(this.info.caseInputChannels, true, "*");
    }

    get marketDataSource(): LookupEntity<unknown>[] {
        return this.markets.map((m) => ({
            id: m.marketId,
            name: `${m.countryCode} / ${m.name} (${m.region})`
        }));
    }

    created(): void {
        this.loadInfo();
        this.loadMarkets();
    }

    isRuleType(ruleTypeNames: string[]): boolean {
        if (this.rule.ruleTypeId) {
            const ruleType = this.ruleTypes.find(
                (r) => r.id === this.rule.ruleTypeId
            );
            return ruleType ? ruleTypeNames.includes(ruleType.name) : false;
        }
        return false;
    }

    updateRuleType(): void {
        this.rule.content = {};
    }

    get codeLevelTypes(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.info?.codeLevelData.codeLevelTypes.filter(
                (c) => !c.isOutdated
            ) ?? [],
            true,
            "*"
        );
    }

    get codeLevelBrands(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.info?.codeLevelData.codeLevelBrands.filter(
                (c) => !c.isOutdated
            ) ?? [],
            true,
            "*"
        );
    }

    get codeLevelAreas(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.info?.codeLevelData?.codeLevelAreas
                .filter((c) => !c.isOutdated)
                .map((a) => ({
                    id: a.id,
                    name: a.name
                })) ?? [],
            true,
            "*"
        );
    }

    get selectedCodeLevelArea(): CodeLevelArea | null {
        return (
            this.info?.codeLevelData?.codeLevelAreas.find(
                (a) => a.id === this.rule.codeLevelAreaId
            ) ?? null
        );
    }

    get codeLevelTopicGroups(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.selectedCodeLevelArea?.topicGroups
                .filter((c) => !c.isOutdated)
                .map((t) => ({
                    id: t.id,
                    name: t.name
                })) ?? [],
            true,
            "*"
        );
    }

    get selectedCodeLevelTopicGroup(): CodeLevelTopicGroup | null {
        return (
            this.selectedCodeLevelArea?.topicGroups.find(
                (t) => t.id === this.rule.codeLevelTopicGroupId
            ) ?? null
        );
    }

    get codeLevelTopicDetails(): LookupEntity<unknown>[] {
        return getSelectDataSource(
            this.selectedCodeLevelTopicGroup?.codeLevelTopicDetails.filter(
                (c) => !c.isOutdated
            ) ?? [],
            true,
            "*"
        );
    }

    // CodeLevelSet cascading related values
    get codeLevelAreaValue(): number | null {
        return this.rule.codeLevelAreaId ?? null;
    }
    set codeLevelAreaValue(value: number | null) {
        if (this.rule.codeLevelAreaId !== value) {
            this.rule.codeLevelTopicGroupId = null;
            this.rule.codeLevelTopicDetailId = null;
        }
        this.rule.codeLevelAreaId = value;
    }

    get codeLevelTopicGroupValue(): number | null {
        return this.rule.codeLevelTopicGroupId ?? null;
    }
    set codeLevelTopicGroupValue(value: number | null) {
        if (this.rule.codeLevelTopicGroupId !== value) {
            this.rule.codeLevelTopicDetailId = null;
        }
        this.rule.codeLevelTopicGroupId = value;
    }

    get codeLevelTopicDetailValue(): number | null {
        return this.rule.codeLevelTopicDetailId ?? null;
    }
    set codeLevelTopicDetailValue(value: number | null) {
        this.rule.codeLevelTopicDetailId = value;
    }
    // -------------------------------------------

    get recommendedPriority(): number {
        return ruleHelper.getRecommendedPriorityForRule(this.rule);
    }
}
