
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
    AddToSubjectRuleContent as Content,
    SubjectSubstitution
} from "@/models/rules/contents/AddToSubjectRuleContent";
import { getSelectDataSourceFromEnum } from "@/services/dataSourceUtils";

@Component
export default class AddToSubjectRuleContent extends Vue {
    @Prop({ type: Object, required: true })
    public readonly content!: Content;

    protected readonly whereDataSource = getSelectDataSourceFromEnum(
        SubjectSubstitution,
        false
    );
}
