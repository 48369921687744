import { render, staticRenderFns } from "./RuleEdit.html?vue&type=template&id=97f37438&"
import script from "./RuleEdit.vue?vue&type=script&lang=ts&"
export * from "./RuleEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports