
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { EmailRuleContent as Content } from "@/models/rules/contents/EmailRuleContent";

@Component
export default class EmailRuleContent extends Vue {
    @Prop({ type: Object, required: true })
    public readonly content!: Content;

    created(): void {
        // Backend supports many emails of each type, but the frontend allows set only one
        this.setContent();
    }

    @Watch("content")
    updateContent(): void {
        this.setContent();
    }

    setContent(): void {
        if (!this.content.sendTo) this.content.sendTo = [];
        if (!this.content.sendCc) this.content.sendCc = [];
        if (!this.content.sendBcc) this.content.sendBcc = [];
    }
}
