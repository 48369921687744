
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import { Routes } from "@/router/routes";
import Vue from "vue";
import Component from "vue-class-component";
import DynamicTitle from "@/components/DynamicTitle";
import RuleEditor from "@/components/RuleEditor";
import { ruleModule } from "@/store/modules/rule";
import { RuleActions } from "@/store/modules/rule/actions";
import { RuleGetters } from "@/store/modules/rule/getters";
import { Rule } from "@/models/rules/Rule";

@Component({
    components: {
        DynamicTitle,
        RuleEditor
    },
    methods: {
        ...ruleModule.mapActions({
            loadRuleById: RuleActions.LoadRuleById,
            updateRule: RuleActions.UpdateRule
        })
    },
    computed: {
        ...ruleModule.mapGetters({
            rule: RuleGetters.Rule,
            errors: RuleGetters.Errors,
            loading: RuleGetters.IsLoading
        })
    }
})
export default class RuleEdit extends Vue {
    private readonly loadRuleById!: (ruleId: number) => Promise<void>;
    private readonly updateRule!: (rule: Partial<Rule>) => Promise<void>;

    protected readonly rule!: Rule | null;
    protected readonly errors!: string[] | null;
    protected readonly loading!: boolean;

    protected snackbar = false;

    protected readonly titleElements: TitleElement[] = [
        { name: "Rules", selected: false, link: Routes.Rules },
        { name: "Edit", selected: true }
    ];

    get ruleId(): number | null {
        const ruleId = Number(this.$route.params.id);
        return !isNaN(ruleId) ? ruleId : null;
    }

    get snackbarErrors(): string {
        if (this.errors?.length) {
            return this.errors.join(" ");
        }
        return "Unknown error!";
    }

    $refs!: {
        form: Vue & { validate: () => boolean };
    };

    protected currentRuleModel: Partial<Rule> = {};

    created(): void {
        if (this.ruleId === null) {
            this.$router.push(Routes.Rules);
        } else {
            this.loadRuleById(this.ruleId).then(() => {
                if (this.rule) {
                    // Remove vuex observer
                    this.currentRuleModel = JSON.parse(
                        JSON.stringify(this.rule)
                    );
                }
            });
        }
    }

    async submit(): Promise<void> {
        const result = this.$refs.form.validate();
        if (result) {
            await this.updateRule(this.currentRuleModel);

            if (!this.errors?.length) {
                this.$router.push(Routes.Rules);
            } else {
                this.snackbar = true;
            }
        }
    }
}
