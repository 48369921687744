import { render, staticRenderFns } from "./RuleList.html?vue&type=template&id=22c22c40&scoped=true&"
import script from "./RuleList.vue?vue&type=script&lang=ts&"
export * from "./RuleList.vue?vue&type=script&lang=ts&"
import style0 from "./RuleList.scss?vue&type=style&index=0&id=22c22c40&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c22c40",
  null
  
)

export default component.exports