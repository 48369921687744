
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ForwardRuleContent as Content } from "@/models/rules/contents/ForwardRuleContent";

@Component
export default class ForwardRuleContent extends Vue {
    @Prop({ type: Object, required: true })
    public readonly content!: Content;
}
